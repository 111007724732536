import type { AuthEventData } from '@monorepo/events/src/types';
import { usePostEvents } from '@monorepo/events';
export const useSendButtonClickEvent = () => {
  const {
    mutate: postEvent
  } = usePostEvents();
  const sendButtonClickEvent = (data: AuthEventData) => {
    postEvent({
      app: 'auth',
      event: 'Button_Clicked',
      scope: 'login',
      action: 'click',
      data: {
        button_name: data.button_name,
        context: data.context
      }
    });
  };
  return {
    sendButtonClickEvent
  };
};